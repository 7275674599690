import { StyledMarkdown } from './Markdown.styled';

interface MarkdownProps {
  center?: boolean;
  children?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ center, children }) => {
  return (
    <StyledMarkdown $center={center} options={{ forceBlock: true }}>
      {children ?? ''}
    </StyledMarkdown>
  );
};

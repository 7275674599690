import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface StyledMarkdownProps {
  $center?: boolean;
}

export const StyledMarkdown = styled(Markdown)<StyledMarkdownProps>`
  text-align: ${({ $center }) => $center && 'center'};
  max-width: 600px;
  p {
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    font-size: ${fontSizes.body};
  }

  h2 {
    font-style: normal;
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    font-size: ${fontSizes.header2Content};
    line-height: ${fontSizes.header2Content};
    margin: 0;
    margin-bottom: ${spacing.regular};
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${fontSizes.header1Content};
      line-height: ${lineHeights.header1Content};
    }
  }

  h3,
  h4 {
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    font-size: ${fontSizes.header2Content};
    line-height: ${fontSizes.header2Content};
  }
`;

import { VisibleSeoBlockFragment } from '@hultafors/solidgear/types';

import { H2 } from '../H2/H2';
import { Markdown } from '../Markdown/Markdown';

import { VisibleSeoBlockStyled } from './VisibleSeoBlock.styled';

export const VisibleSeoBlock: React.FC<VisibleSeoBlockFragment> = ({
  title,
  description,
  borderTop,
}) => {
  return (
    <VisibleSeoBlockStyled $borderTop={borderTop}>
      {title && <H2>{title}</H2>}
      {description && <Markdown center={true}>{description}</Markdown>}
    </VisibleSeoBlockStyled>
  );
};

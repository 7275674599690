import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface VisibleSeoBlockStyledProps {
  $borderTop: boolean;
}

export const VisibleSeoBlockStyled = styled.div<VisibleSeoBlockStyledProps>`
  border-top: ${({ $borderTop }) => $borderTop && `1px solid ${colors.gray4}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${colors.gray4};
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.medium};
  margin-inline: auto;
  max-width: 1200px;
  padding-block: ${spacing.large};

  h2 {
    font-style: normal;
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    font-size: ${fontSizes.header2Content};
    line-height: ${fontSizes.header2Content};
    margin: 0;
    margin-bottom: ${spacing.regular};
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${fontSizes.header1Content};
      line-height: ${lineHeights.header1Content};
    }
  }
`;
